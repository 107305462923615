<template>
  <b-overlay :show="showOverlay" rounded="sm">
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div v-if="Tickets !== null" style="height: 85vh">
      <div class="body-content-overlay" />

      <!-- Main Area -->
      <section class="chat-app-window w-100">
        <!-- Chat Content -->
        <div class="active-chat">
          <!-- Chat Navbar -->
          <div class="chat-navbar">
            <header class="chat-header">
              <!-- Avatar & Name -->
              <div class="d-flex align-items-center">
                <b-avatar
                  v-if="Tickets !== null && Tickets[0].userInfo"
                  size="36"
                  :src="`https://api.1minex.com/${Tickets[0].userInfo.selfieFileData}`"
                  class="mr-1 cursor-pointer badge-minimal"
                />

                <b-avatar
                  v-else
                  size="36"
                  src="@/assets/images/logo/logo-jadid.png"
                  class="mr-1 cursor-pointer badge-minimal"
                />
                <span>{{ Tickets[0].userInfo.userName }}</span>
              </div>
            </header>
          </div>

          <!-- User Chat Area -->
          <vue-perfect-scrollbar
            ref="refChatLogPS"
            style="max-height: 72vh; min-height: 72vh"
            :settings="perfectScrollbarSettings"
            class="user-chats scroll-area"
          >
            <template v-if="Tickets !== null && Tickets[0].userInfo">
              <ticket-log
                v-for="(Ticket, index) in Tickets"
                :key="index"
                :chat-data="Ticket"
                :profile-user-avatar="`https://api.1minex.com/${Tickets[0].userInfo.selfieFileData}`"
                :admin="admin"
                :user="user"
              />
            </template>
          </vue-perfect-scrollbar>

          <!-- Message Input -->
          <b-form class="chat-app-form">
            <b-input-group class="input-group-merge form-send-message mr-1">
              <b-form-input
                @keydown.enter.prevent="SendMessage"
                v-model="content"
                placeholder="Your Message..."
              />
            </b-input-group>
            <feather-icon
              @click="showUploadModal = true"
              v-b-modal.modal-upload
              class="text-warning mr-1"
              icon="PaperclipIcon"
              size="35"
            />
            <b-button @click="SendMessage" variant="primary" type="button">
              Send
            </b-button>
          </b-form>
        </div>
      </section>
    </div>

    <!--  Upload Pic Modal   -->
    <b-modal
      v-if="showUploadModal"
      id="modal-upload"
      centered
      title="Upload photo"
      ok-title="Send"
      cancelTitle="Cansel"
      @cancel="closeUploadModal"
      @ok="SendMessage"
    >
      <b-media class="my-2 col-12 mr-5">
        <template>
          <b-avatar
            class="w-100 cursor-pointer mx-auto"
            ref="previewEl"
            :src="base64ImageSrc"
            size="300px"
            rounded
            @click.native="$refs.refInputEl1.click()"
          >
            <feather-icon
              v-if="base64ImageSrc === null"
              icon="PlusSquareIcon"
              size="100"
            />
          </b-avatar>
        </template>
        <div class="d-none flex-wrap mt-1">
          <b-button variant="primary">
            <input
              ref="refInputEl1"
              type="file"
              class="d-none"
              accept="image/*"
              @input="makeBase64Pic($event, 'base64ImageSrc')"
            />
            <span class="d-none d-sm-inline">+</span>
            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
          </b-button>
        </div>
      </b-media>

      <b-input-group class="input-group-merge form-send-message mt-2">
        <b-form-input v-model="picText" placeholder="Your message..." />
      </b-input-group>
    </b-modal>
    <!--  Upload Pic Modal End  -->
  </b-overlay>
</template>

<script>
// eslint-disable-file
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { getUserData } from '@/auth/utils'
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BOverlay,
  BMedia,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

import vSelect from 'vue-select'
import {
  TicketCreateRequest,
  TicketGetAllByParentIdRequest,
} from '@/libs/Api/Ticket'
import ChatLog from '../../chat/ChatLog.vue'
import TicketLog from './TicketLog.vue'
import useChat from '../../chat/useChat'

export default {
  title: 'Ticket',
  data() {
    return {
      ticketId: this.$route.params.id,
      showOverlay: false,
      user: null,
      Tickets: null,
      admin: null,
      base64ImageSrc: null,
      pic: null,
      picText: '',
      showUploadModal: false,
      refreshTicket: null,
      TicketTypesId: [
        {
          option: 'احراز هویت',
          value: 1,
        },
        {
          option: 'پشتیبانی',
          value: 2,
        },
        {
          option: 'سایر',
          value: 3,
        },
      ],
      ticketType: 0,
      content: '',
    }
  },

  computed: {
    currentDate() {
      let today = new Date()
      let date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate()
      let time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      return date + ' ' + time
    },
  },
  watch: {},
  async created() {
    await this.GetAllByParentId()
    const userData = getUserData()
    this.admin = userData.data.user
  },

  methods: {
    async GetAllByParentId() {
      let _this = this
      _this.showOverlay = true
      let ticketGetAllByParentIdRequest = new TicketGetAllByParentIdRequest(
        _this
      )
      let data = {
        id: this.$route.params.id,
      }
      ticketGetAllByParentIdRequest.setParams(data)
      await ticketGetAllByParentIdRequest.fetch(
        (content) => {
          _this.showOverlay = false
          _this.Tickets = content
          _this.scrollToBottom()
        },
        (e) => {
          _this.showOverlay = false
          console.log(e)
        }
      )
    },
    async SendMessage() {
      let _this = this
      _this.showOverlay = true
      let ticketCreateRequest = new TicketCreateRequest(_this)
      let data = {
        parentId: _this.$route.params.id,
        content: _this.content,
        type: _this.Tickets[0].type,
        title: _this.Tickets[0].title,
        createDate: new Date(Date.now()),
        ticketId: 0,
        userType: 2,
        isRead: false,
        fileData: null,
      }
      if (_this.pic !== null) {
        data.fileData = _this.pic
        data.content = _this.picText
      }
      ticketCreateRequest.setRequestParamDataObj(data)
      await ticketCreateRequest.fetch(
        () => {
          _this.showOverlay = false
          _this.GetAllByParentId()
          _this.content = ''
        },
        (e) => {
          _this.showOverlay = false
          console.log(e)
        }
      )
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader()
        fr.onerror = reject
        fr.onload = () => {
          resolve(fr.result.split(',')[1])
        };
        fr.readAsDataURL(file)
      })
    },
    async makeBase64Pic(e, index) {
      const _this = this
      let file = e.target.files[0]
      _this[index] = URL.createObjectURL(file)
      const result = await _this.readAsDataURL(file)
      _this.pic = result
    },
    closeUploadModal() {
      this.pic = null
      this.picText = null
      this.base64ImageSrc = null
      this.showUploadModal = false
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.refChatLogPS.$el
        container.scrollTop = container.scrollHeight
      })
    },
  },
  components: {
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    vSelect,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    VuePerfectScrollbar,
    ChatLog,
    TicketLog,
    BMedia,
  },
  setup() {
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    };

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])
    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = (userId) => {
      // Reset send message input value
      chatInputMessage.value = ''

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    };
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    return {
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>

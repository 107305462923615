import WebServiceRequest from '../Api/WebServiceRequest'

class MessageGetConversationRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setData(data){
        super.setTag('Message/GetConversation?userId='+data.userId+'&count='+data.count+'&MessageId='+data.MessageId)
    }
}
class MessageDeliverRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Message/DeliverMessage')
    }
}
class MessageGetMenuRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setRequestParam(search) {
        super.setTag('Message/GetMenu?searchCommand='+search)
    }
}
class MessageReadRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Message/ReadMessage?userId='+id)
    }
}
class MessageSendRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Message/SendMessage')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class MessageDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Message/Delete?id='+id)
    }
}
class MessageGetChannelMenuRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Message/GetChannelMenu')
    }
}
class MessageGetChannelMessagesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Message/GetChannelMessages')
    }
    setParam(data){
        super.setRequestParam(data)
    }
}
export {MessageGetConversationRequest,MessageDeliverRequest,MessageGetMenuRequest,MessageReadRequest,MessageSendRequest,MessageDeleteRequest,MessageGetChannelMenuRequest,MessageGetChannelMessagesRequest};
